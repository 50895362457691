<template>
<div>
  <!-- penambahan additional text atau menu -->
  <slot name="top"></slot>

  <!-- toolbar -->
  <v-toolbar flat class="pa-0 my-2" style="border-bottom:1px solid #eee">
    <slot name="header">
        <v-toolbar-title v-if="settings.enableHeader && settings.title">{{settings.title}}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical v-if="settings.enableHeader && settings.title"></v-divider>
        <slot name="prepend-header"></slot>
        <v-text-field single-line hide-details style="max-width:300px" :label="settings.searchLabel" append="mdi-magnify" append-icon="mdi-magnify" v-model="search" v-if="settings.enableHeader"></v-text-field>
        <v-spacer></v-spacer>
        <slot name="append-header"></slot>
    </slot>
  </v-toolbar>

  <v-progress-linear
      indeterminate
      color="pink"
      v-if="dataLoading"
  ></v-progress-linear>
  <v-data-table
    :headers="headers"
    :items="items"
    :server-items-length="totalItems"
    :page.sync="page"
    :items-per-page.sync="itemsPerPage"
    :loading="loading"
    :footer-props="{'items-per-page-options':[5,10,15]}"
    :hide-default-footer="hideFooter"
  >
    <!-- Numbering -->
    <template v-slot:item.index="{ item, index }">
    {{ index+1 }}
    </template>
    <template v-slot:item.no="{item, index}">
      <div>
        {{index + 1}}
      </div>
    </template>
    <!-- penambahan e-sakip u/ SKP Tahunan-->
    <template v-slot:item.sasaran_kinerja_tahunan="{item}">
      <!-- {{ item.sasaran_kinerja_tahunan != null && item.sasaran_kinerja_tahunan != '' ? item.sasaran_kinerja_tahunan : item.rencana_kinerja }} -->
      <div v-if="item.rencana_kinerja != '' && item.rencana_kinerja != null">
        {{ item.rencana_kinerja }} <br>
        <!-- <i><strong><font color="black">(Format: Permenpan No. 8/2021)</font></strong></i><br> -->
        <i><strong><font color="black">(Format: Permenpan No. 6/2022)</font></strong></i><br>
        <div v-if="item.is_sakip">
          <i><label class="control-label" style="color:red">(SKP ini sinkronisasi dengan SAKIP)</label></i>
        </div>
      </div>
      <div v-else>
      <div v-if="item.sasaran_kinerja_tahunan != null && item.sasaran_kinerja_tahunan != ''">
        {{ item.sasaran_kinerja_tahunan }} <br>
        <i><strong><font color="red">(Format: PP 46/2011)</font></strong></i>
      </div>
      </div>
    </template>

    <template v-slot:item.indikator="{item}">
      <div v-if="item.rencana_kinerja == null">
        {{ item.sasaran_strategis == null ? item.indikator : item.sasaran_strategis }}
      </div>
      <div v-else>
        <!-- {{ item.indikator}}<br> -->
        {{ item.indikator == null || item.indikator == " - " || item.indikator == "-" ? '' : 'Indikator: '+item.indikator }}
        {{ item.indikator_kuantitas != null ? 'Kuantitas: '+item.indikator_kuantitas : '' }}<br>
        {{ item.indikator_kualitas != null ? 'Kualitas: '+item.indikator_kualitas : '' }}<br>
        {{ item.indikator_waktu!= null ? 'Waktu: '+item.indikator_waktu : '' }}
      </div>
      <!-- {{ item.sasaran_strategis == null ? item.indikator : item.sasaran_strategis }} -->
      <v-chip class="red px-1" x-small label v-if="item.is_sakip"><v-img max-width="50" src="@/assets/logo_aplikasi_lain/ESAKIP.png"></v-img></v-chip>
      <!-- <span class="error--text" style="font-size:11px" v-if="item.is_sakip">SKP ini Sinkronisasi dengan SAKIP</span> -->
    </template>

    <template v-slot:item.target="{item}">
      <br>
      <!-- <span class="font-weight-medium"> Target: </span> 
      <div v-if="item.revisi_target == null">
          {{item.target != null ? item.target : item.target_bawah != null ? item.target_bawah : ''}}
          {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{item.satuan_target}}
      </div>
      <div v-else>
        <strike>{{ item.target }} {{ item.satuan_target }}</strike><br>&nbsp;&nbsp;{{item.revisi_target}} {{item.satuan_target}}
      </div> -->
      <!-- <div style="width:120px !important" class="my-1"><span class="font-weight-medium">
        Target: </span> {{item.target != null ? item.target : item.target_bawah != null ? item.target_bawah : ''}}
        {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{item.satuan_target}}
      </div> -->
      <div v-if="item.rencana_kinerja != null">
        <span v-if="item.target_bawah != null">
          Target Kuantitas: {{ item.target_bawah != null ? item.target_bawah : ''  }} {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{ item.satuan_target }}
        </span>
        <br>
        <span v-if="item.kualitas_batas_bawah != null">
          Target Kualitas: {{ item.kualitas_batas_bawah != null ? item.kualitas_batas_bawah : ''  }} {{ item.kualitas_batas_atas != null ? ' - '+item.kualitas_batas_atas : ''  }} %
        </span>
        <br>
        <span v-if="item.waktu_batas_bawah != null">
          Target Waktu: {{ item.waktu_batas_bawah != null ? item.waktu_batas_bawah : ''  }} {{ item.waktu_batas_atas != null ? ' - '+item.waktu_batas_atas : ''  }} bulan
        </span>

        <span class="my-1 success--text" v-if="item.realisasi_kuantitas != null"><b>Realisasi Kuantitas:</b> {{ item.realisasi_kuantitas+ ' ' +item.satuan_target }}</span>
        <br>
        <span class="my-1 success--text" v-if="item.realisasi_kualitas != null"><b>Realisasi Kualitas:</b> {{ item.realisasi_kualitas+ ' %' }}</span>
        <br>
        <span class="my-1 success--text" v-if="item.realisasi_waktu != null"><b>Realisasi Waktu:</b> {{ item.realisasi_waktu+ ' bulan' }}</span>
        <br>
      </div>

      <div v-else>
        <span class="font-weight-medium"> Target: </span> 
        <div v-if="item.revisi_target == null">
            {{item.target != null ? item.target : item.target_bawah != null ? item.target_bawah : ''}}
            {{ item.target_atas != null ? ' - '+item.target_atas : ''  }} {{item.satuan_target}}
        </div>
        <div v-else>
          <strike>{{ item.target }} {{ item.satuan_target }}</strike><br>&nbsp;&nbsp;{{item.revisi_target}} {{item.satuan_target}}
        </div>
        <div class="my-1 success--text" v-if="item.realisasi"><span class="font-weight-medium ">Realisasi: </span> {{item.realisasi || '-'}} {{item.satuan_target}}</div>
        <div class="my-1" v-if="item.kualitas != null"><span class="font-weight-medium">Kualitas:</span> {{item.kualitas || '-'}} %</div>
      </div>
    </template>

    <template v-slot:item.target_triwulan="{item}">
      <div class="my-1" v-if="item.target"><span class="font-weight-medium ">Target: </span> {{item.target}} {{item.satuan_target}}</div>
      <div class="my-1 success--text" v-if="item.realisasi"><span class="font-weight-medium ">Realisasi: </span> {{item.realisasi || '-'}} {{item.satuan_target}}</div>
      <!-- <div class="my-1" v-if="item.kualitas != null"><span class="font-weight-medium">Kualitas:</span> {{item.kualitas || '-'}} %</div> -->
    </template>

    <template v-slot:item.waktu="{item}">
      <div v-if="item.rencana_kinerja != null">
        <strike>{{ filterWaktu(item, item.waktu) }}</strike><br>
        {{ item.revisi_waktu != null ? filterWaktu(item, item.revisi_waktu) : filterWaktu(item, item.periode_diff) }}
      </div>
      <div v-else>
        <div v-if="item.revisi_waktu != null">
          <strike>{{ filterWaktu(item, item.waktu) }}</strike><br>
          {{ item.revisi_waktu != null ? filterWaktu(item, item.revisi_waktu) : filterWaktu(item, item.waktu) }}
        </div>
        <div v-else>
          {{ filterWaktu(item, item.waktu) }}
        </div>
      </div>
    </template>
    <!-- batas SKP Tahunan -->

    <!-- penambahan u/ SKP Triwulan-->
    <template v-slot:item.sasaran="{item}">
      {{item.sasaran}}
      <v-chip class="red px-1" x-small label v-if="item.is_sakip"><v-img max-width="50" src="@/assets/logo_aplikasi_lain/ESAKIP.png"></v-img></v-chip>
      <!-- <span class="error--text" style="font-size:11px" v-if="item.is_sakip">SKP ini Sinkronisasi dengan SAKIP</span> -->
    </template>
    <template v-slot:item.rencana_kinerja="{item}">
      {{ item.rencana_kinerja != null ? item.rencana_kinerja : item.rencana_kinerja_tahunan != null ? item.rencana_kinerja_tahunan : item.sasaran_kinerja_tahunan  }}
    </template>
    <template v-slot:item.indikator_bulanan="{item}">
      {{ item.indikator_bulanan }}
    </template>
    <!-- <template v-slot:item.sasaran_kinerja_tahunan="{item}">
      {{ item.sasaran_kinerja_tahunan != null && item.sasaran_kinerja_tahunan != '' ? item.sasaran_kinerja_tahunan : item.rencana_kinerja_tahunan }}
    </template> -->
    <!-- batas SKP Triwulan -->

    <!-- penambahan u/ barang bukti foto PPNS -->
    <template v-slot:item.foto="{item}">
      <div class="d-flex flex-column">
        <!-- <a :href="urlEvidencePPNS(f)" target="_blank" v-for="(f,i) in item.foto" :key="i">{{parseFilename(f)}}</a> -->
        <a :href="urlEvidencePPNS(f)" target="_blank" v-for="(f,i) in parseFilenamePPNS(item.foto)" :key="i">{{f}}</a>
      </div>
    </template>
    <!-- batas u/ barang bukti foto PPNS -->

    <!-- penambahan u/ barang bukti file PBJ -->
    <template v-slot:item.file="{item}">
      <div class="d-flex flex-column">
        <a :href="urlEvidencePBJ(f)" target="_blank" v-for="(f,i) in parseFilenamePBJ(item.file)" :key="i">{{f}}</a>
      </div>
    </template>
    <!-- batas u/ barang bukti file PBJ -->

    <!-- penambahan u/ barang bukti file LHP / Kabag Setwan -->
    <template v-slot:item.file_="{item}">
      <div class="d-flex flex-column">
        <a :href="urlEvidence(f)" target="_blank" v-for="(f,i) in parseFilename(item.file)" :key="i">{{f}}</a>
      </div>
    </template>
    <!-- batas u/ barang bukti file PBJ -->

    <!-- tombol aksi -->
    <template v-slot:item.aksi="{ item }">
      <slot name="append-actions" :item="item"></slot>

      <v-btn small color="info" class="ma-1" @click="detailItem(item)" v-if="settings.enableDetail" >
        <v-icon  title="detail">
          mdi-magnify
        </v-icon>
        Detail
      </v-btn>

      <template>
        <div v-if="currentTriwulan()">
          <v-btn x-small color="secondary" class="ma-1" @click="editItem(item)" v-if="enableEditRule(item)" >
            <v-icon x-small left title="ubah data">
              mdi-pencil
            </v-icon>
            Ubah
          </v-btn>

          <v-btn x-small color="error" class="ma-1" @click="deleteItem(item)" v-if="enableDeleteRule(item)" >
            <v-icon x-small left title="hapus">
              mdi-delete
            </v-icon>
            Hapus
          </v-btn>
        </div>
        <div v-else-if="settings.enableEdit">
          <v-btn x-small color="secondary" class="ma-1" @click="editItem(item)" v-if="enableEditRule(item)" >
            <v-icon x-small left title="ubah data">
              mdi-pencil
            </v-icon>
            Ubah
          </v-btn>

          <v-btn x-small color="error" class="ma-1" @click="deleteItem(item)" v-if="enableDeleteRule(item)" >
            <v-icon x-small left title="hapus">
              mdi-delete
            </v-icon>
            Hapus
          </v-btn>
        </div>
      </template>

      <v-btn x-small v-bind:color="item.nip_assignments != null ? 'success' : 'default'" class="ma-1" @click="assignmentItem(item)"
        v-if="item.status_bawahan && item.rencana_kinerja != null && item.rencana_kinerja != '' && (item.target_bawah != null || item.kualitas_batas_bawah != null || item.waktu_batas_bawah != null)" >
        <v-icon x-small left title="Assignment Pembagian Matriks Peran-Hasil Rencana Kinerja">
          {{item.nip_assignments == null ? "mdi-plus" : "mdi-check"}}
        </v-icon>
        <strong>Assignment</strong>
      </v-btn>

      <!-- <v-btn x-small color="default" class="ma-1" @click="lampiranAngkaKredit(item)"
        v-if="item.rencana_kinerja != null && item.rencana_kinerja != '' && item.jft != null && item.skp_jabatan_saat_ini == true">
        <v-icon x-small left title="Angka Kredit">mdi-paperclip</v-icon>
        <strong>Angka Kredit</strong>
      </v-btn> -->


    </template>

    <!-- tombol aksi skp tambahan -->
    <template v-slot:item.aksi_skp_tambahan="{ item }">
      <slot name="append-actions" :item="item"></slot>
      <template>
        <v-btn x-small color="secondary" class="ma-1" @click="editItem(item)" v-if="enableEditRuleSKPTambahan(item)" >
          <v-icon x-small left title="ubah data">
            mdi-pencil
          </v-icon>
          Ubah
        </v-btn>

        <v-btn x-small color="error" class="ma-1" @click="deleteItem(item)" v-if="enableDeleteRuleSKPTambahan(item)" >
          <v-icon x-small left title="hapus">
            mdi-delete
          </v-icon>
          Hapus
        </v-btn>
      </template>
    </template>

    <template v-slot:item.teks_status="{item}">
      <div :class="item.skp_jabatan_saat_ini ?'my-1 success--text' : 'my-1 error--text'">
        <span class="font-weight-medium ">{{item.teks_status}} </span>
      </div>
    </template>


    <!-- penambahan u/ menu IKP -->
    <template v-slot:item.instruksi="{item}">
      <br>
      {{item.instruksi}}
      <br><br>
      <v-badge color="pink" top v-if="item.support" content="Support"></v-badge>
      <v-badge color="pink" top v-else content="Delegasi Atasan"></v-badge>
      <br><br>
    </template>

    <template v-slot:item.leading="{item}">
      <div v-if="item.support_sector_nama.length > 0">
        <br>
        {{item.kolok == null ? item.unit_kerja_nama : item.satuan_kerja_nama}}
        <br><br>
        <b>Supporting Sector : </b>
        <template v-for="(support,index) in item.support_sector_nama">
            <div v-bind:key="index" v-if="index <= 5">
                - {{support}}
            </div>
            <div v-bind:key="index" v-if="index == 6">
                - dll
            </div>
        </template>
        <br>
      </div>
    </template>

    <template v-slot:item.status="{item}">
      {{ (item.lapor.progress == null || item.lapor.progress == 0) ? 'Instruksi Belum Dikerjakan Oleh SKPD' : 'Instruksi Sudah Dilaksanakan dan Dilaporkan' }}
      <br><br>
      Progress{{item.support ? ' Supporting' : ''}} : {{item.lapor ? item.lapor.progress : item.progress}}% 
      <br><br>
      <v-badge color="green" top v-if="item.progress == 100" content="Sudah Selesai"></v-badge>
      <v-badge color="red" top v-else content="Belum Selesai"></v-badge>
    </template>

    <template v-slot:item.tanggal_ins="{item}">
      {{item.tanggal_format}}
    </template>

    <template v-slot:item.deadline="{item}">
      {{item.deadline_format}}
    </template>

    <template v-slot:item.tanggal_lpr="{item}">
      {{item.lapor ? (item.lapor.progress == 0 ? 'Belum Dilaporkan' : item.tanggal_format) : 'Belum Dilaporkan'}} 
    </template>

    <template v-slot:item.hari="{item}">
      {{item.hari_ini > item.deadline ? (item.validasi == 'belum' ? 'Sudah Terlewat' : '-') : item.sisa_hari + ' Hari'}}
    </template>

    <template v-slot:item.aksi_data_table_ikp="{ item }">
        <v-btn x-small color="secondary" class="ma-1" @click="detailIKP(item)">
          <v-icon x-small left title="Lihat Detail Laporan">
            mdi-magnify
          </v-icon>
          Lihat Detail Laporan
        </v-btn>

        <v-btn x-small color="success" class="ma-1" @click="laporIKP(item)">
          <v-icon x-small left title="Laporkan IKP">
            mdi-check
          </v-icon>
          Laporkan
        </v-btn>

        <v-btn x-small color="yellow" class="ma-1" @click="validasiIKP(item)" v-if="!item.support && item.support_sector_nama.length > 0" >
          <v-icon x-small left title="Validasi IKP Supporting">
            mdi-file-check
          </v-icon>
          Validasi IKP Supporting Sectors
        </v-btn>
    </template>

  </v-data-table>

  <!-- detail dialog -->
  <v-dialog v-model="detailDialog" max-width="700px" v-if="detailData">
    <v-card >
      <v-card-title class="pa-0">
        <v-toolbar flat>
          <v-toolbar-title>{{settings.detailTitle}} <span class="font-weight-medium" ><q>{{detailData['Nama']}}</q> </span> </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="detailDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        <loading v-if="detailLoading"></loading>
        <div v-else>
          <table class="text-body-1 font-weight-normal">
            <tr v-for="(v,k,i) in detailData" :key="i">
              <td v-if="!k.match(/noDisplay.*/)" class="px-5 py-2">{{k}}</td>
              <td v-else></td>
              <td v-html="v"></td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- delete dialog -->
  <v-dialog v-model="removeDialog" max-width="500px">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar flat >
          <v-toolbar-title>Hapus</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="removeDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        Hapus {{deletedItem[settings.deleteTitleKey]}} ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="red" @click="remove()">hapus</v-btn>
        <v-btn outlined color="blue" @click="removeDialog=false">batal</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import Loading from '@/components/base/Loading'

export default {
  name: 'Crud',
  components:{
    Loading,
  },

  props: {
    headers: Array,
    detailData: Object,
    crudSettings: Object,
    forceUpdateTable: Boolean,
    dataLoading:Boolean,
    hideFooter:{
      type: Boolean,
      default: false
    },
    responseData: {
      type: Object,
      default: function() {
        return {
          data: []
        }
      }
    },
    tabTriwulan: String
  },

  data: () => ({
    dialog: false,
    detailDialog: false,
    detailLoading:false,
    removeDialog:false,
    editedIndex: -1,
    page: 1,
    itemsPerPage: 10,
    // totalItems:100,
    loading: false,
    search: '',
    deletedItem: {},
    editedItem: {},
    defaultItem: {},
    forceUpdateFlag: false,
    defaultSettings:{
      title: 'CRUD',
      detailTitle: 'Detail',
      enableHeader:true,
      enableDetail:true,
      enableCreate:true,
      enableEdit:true,
      enableDelete:true,
      enableFeedback:false,
      deleteTitleKey:'title',
      searchLabel:'Cari'
    },
    classAssignments: ''
  }),

  computed: {

    settings(){
      let s = {...this.defaultSettings, ...this.crudSettings}
      return s
    },

    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },

    items() {
      return this.responseData.data
    },

    totalItems() {
      return this.responseData.total_keseluruhan * 1
    },

    filterList(){
      return []
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    page(val) {
      console.log(val)
      this.loading = true
      this.$emit('onTableChange', {
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        page: this.page
      })
    },

    forceUpdateTable(val){
      console.log('force update flag',  val)
      if (val && this.forceUpdateFlag){
        this.page = 1
        this.$emit('onTableChange', {
          search: this.search,
          itemsPerPage: this.itemsPerPage,
          page: this.page
        })
        this.forceUpdateFlag = false
      } else if( val == false) {
          this.forceUpdateFlag = true
      }
    },

    responseData(val) {
      console.log('responseData',val)
      this.loading = false
    },

    detailData(val){
      console.log('detailData', val)
      this.detailLoading=false
    }
  },

  created() {
    this.initialize()
  },

  mounted() {
    // watch multiple values
    this.$watch(vm => [vm.itemsPerPage, vm.search], val => {
      this.page = 1
      this.$emit('onTableChange', {
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        page: this.page
      }, {
        immediate: true,
        deep: false
      })
    })
  },

  methods: {
    currentTriwulan(){
      let date = new Date()
      let month = date.getMonth() + 1;
      let triwulan = (Math.ceil(month / 3)).toString()
      if(this.tabTriwulan == triwulan)return true; else if(triwulan == 1) return true; else return false
    },
    enableEditRule(item){
      return this.settings.enableEdit && ![1,4].includes(item.validasi) || (item.verifikasi == false)
      // return this.settings.enableEdit && !item.is_sakip && ![1,4].includes(item.validasi) || (item.verifikasi == false)
    },

    enableDeleteRule(item){
      return this.settings.enableDelete && ![1,4].includes(item.validasi)
      // return this.settings.enableDelete && !item.is_sakip && ![1,4].includes(item.validasi)
    },
    enableEditRuleSKPTambahan(item){
      return this.settings.enableEdit
    },
    enableDeleteRuleSKPTambahan(item){
      return this.settings.enableDelete
    },

    filterWaktu(item, waktu){
      let waktu_ = waktu
      let satuan = item.satuan_waktu

      if (!isNaN(waktu_)){
        return waktu_ + " " + satuan
      }else if(waktu_.search('null') > -1 ){
        return '-'
      }
      return waktu_
    },

    initialize() {},

    detailItem(item) {
      this.detailDialog = true
      this.detailLoading = true
      // this.$emit('onDetailClick', item.id)
      this.$emit('onDetailClick', item)
    },

    editItem(item) {
      this.$emit('onEditClick', item)
    },

    deleteItem(item) {
      this.deletedItem = Object.assign({}, item)
      console.log(this.deletedItem)
      this.removeDialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    remove(){
      this.$emit('onDeleteClick', this.deletedItem)
      this.removeDialog =false
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.$emit('onEditClick',this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
        this.$emit('onCreateClick', this.editedItem)
      }
      this.close()
    },

    urlEvidencePPNS(filename){
      // return process.env.VUE_APP_EVIDENCE_URL+"penyidik/"+filename
      return process.env.VUE_APP_EVIDENCE_URL+"/"+filename
    },

    urlEvidencePBJ(filename){
      // return process.env.VUE_APP_EVIDENCE_URL+"pejabat-pengadaan/"+filename
      return process.env.VUE_APP_EVIDENCE_URL+"/"+filename
    },

    urlEvidence(filename){
      return process.env.VUE_APP_EVIDENCE_URL+"/"+filename
    },

    parseFilename(filename){
      let parsedFilename = JSON.parse(filename)
      // let parsedFilename = /\d+_(.*)-[\w\d]+(\.\w+)/g.exec(filename)
      try{
        return parsedFilename
        // return parsedFilename[1] + parsedFilename[2]
      } catch(e){
        return filename
      }
    },

    parseFilenamePPNS(filename){
      return filename
    },

    parseFilenamePBJ(filename){
      if(!Array.isArray(filename)){
        let parsedFilename = JSON.parse(filename)
        // let parsedFilename = /\d+_(.*)-[\w\d]+(\.\w+)/g.exec(filename)
        try{
          return parsedFilename
          // return parsedFilename[1] + parsedFilename[2]
        } catch(e){
          return filename
        }
      }else{
        return filename
      }
    },

    assignmentItem(item) {
      this.$emit('onAssignmentClick', item)
    },

    lampiranAngkaKredit(item){
      this.$emit('onLampiranAngkaKreditClick', item)
    },

    detailIKP(item) {
      this.$emit('onDetailIKPClick', item)
    },

    laporIKP(item) {
      this.$emit('onLaporIKPClick', item)
    },

    validasiIKP(item){
      this.$emit('onValidasiIKPClick', item)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
